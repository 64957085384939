import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
    return (
        <Layout pageTitle="about me">
            <p>Software engineer speciliazing in DevOps</p>
        </Layout>
    )
}

export default AboutPage